export default function contact() {

    //hover時にフォームを画面下から引き上げる
    $('.form__title--lg-img').mouseover(function () {
        $('.form').css('bottom', '0px');
    })

    $('.form__title--sp-img').mouseover(function () {
        $('.form').css('bottom', '0px');
    })

    $('.form').mouseleave(function () {
        if (window.innerWidth < 700)
            $('.form').css('bottom', '-650px');
        else if (window.innerWidth < 1000) {
            $('.form').css('bottom', '-450px');
        }
    })

    //画面サイズ変化によるフォームの上下位置調整
    $(window).on('load resize', function () {
        let FormBottom = $('.form').css('bottom');
        if (window.innerWidth > 700 && $('.form').css('bottom') === '-650px') {
            $('.form').css('bottom', '-450px');
        } else if (window.innerWidth < 700 && $('.form').css('bottom') === '-450px') {
            $('.form').css('bottom', '-650px');
        }
    });
}

//step1バリデーション
$('input[name="firm"]').on('blur keyup', function () {
    var firm = $(this).val();
    if (!firm) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!会社名は入力必須です</span>');
        }
    }
    else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }
});

$('input[name="name"]').on('blur keyup', function () {
    var name = $(this).val();
    if (!name) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!名前は入力必須です</span>');
        }
    }
    else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }
});

$('input[name="email"]').on('blur keyup', function () {
    let email = $(this).val();
    let email_result = email.match(/^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/)
    if (!email) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!メールアドレスは入力必須です</span>');
        }
    }
    else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }

    if (!email_result) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!アドレスの形式が正しくありません</span>');
        }
    } else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }
});

$('input[name="phone"]').on('blur keyup', function () {
    let phone = $(this).val();
    let tel = $(this).val().replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, '');
    let phone_result = tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)
    if (phone.length < 8) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!7桁以上の電話番号を入力してください</span>');
        }
    } else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }
    if (!phone_result) {
        if (!$(this).nextAll('span.error-info').length) {
            $(this).after('<span class = "error-info">!電話番号の形式が正しくありません</span>');
        }
    } else {
        if ($(this).nextAll('span.error-info').length) {
            $(this).nextAll('span.error-info').remove();
        }
    }
});

//電話番号の入力制限
$(function () {
    var $input = $('input[name="phone"]');
    $input.on('input', function (event) {
        let str = $input.val();
        let str_last = str.slice(-1);
        let str_strange = str.slice(0, -1);

        if (str_last.match(/[^0-9\-]/)) {
            // そうでなければ入力文字を空白に変換
            $('input[name="phone"]').val(str_strange);
        }
    })
});

//step2選択済みかの確認処理
let array = [];

function radio_check(name) {
    let c = 0;
    $('input[name=' + name + ']').each(function () {
        if ($(this).prop('checked')) { c++; }
    });
    if (!c) {
        array.push('error')
    }
}

function select_check(idname) {
    var selected = $('#' + idname + ' option:selected');
    if (!selected.val()) {
        array.push('error');
    }
}

function box_check() {
    if ($('input[name="check"]').prop('checked')) { }
    else {
        array.push('error');
    }
}

//送信ボタンクリック時のアニメーション
$(".form-btn1").click(function () {
    if ($('.error-info').length || !$('input[name="firm"]').val() || !$('input[name="name"]').val() || !$('input[name="email"]').val() || !$('input[name="phone"]').val()) {
    } else {
        $('.step1').hide();
        $('.step2').fadeIn(1000);
        $('.step2').removeClass("no-active");
        $('.form-btn1').hide();
        $('.form-btn2').fadeIn(1000);
        $('.form-btn2').removeClass("no-active");
        $('.form-btn2').css('display', 'block');
        $('.toggle-active').addClass('active');
        $('.animation').addClass('animation-border');
    }
});

$(".form-btn2").click(function () {
    radio_check("ad");
    radio_check("inspection");
    select_check('site');
    select_check('budget');
    box_check();

    //選択肢を全て回答しているかの判定
    if ($.inArray('error', array) === -1) {
        $('.step1').addClass("no-active");
        $('.step2').removeClass("active");
        $('.step2').addClass("no-active");
        $('.step3').addClass('active')
        $('.form-btn2').removeClass("active");
        $('.form-btn2').addClass("no-active");
        $('.attention').addClass("no-active")
        $('.toggle-active2').addClass('active');
        $('.animation2').addClass('animation-border');

        //ajax通信
        let form_data = $('form').serialize();
        $.ajax({
            type: "POST",
            url: './app/index.php',
            data: form_data
        }).done(function (result) {
        }).fail(function (XMLHttpRequest, status, e) {
            alert(e);
        });
    } else {
        array.length = 0;
    }
});

//スワイプ処理
$(".form").on("touchstart", start_check);
$(".form").on("touchmove", move_check);
$(".form").on("touchend", end_check);
let moveY, posiY;

function start_check(e) {
    posiY = getY(e);
    moveY = '';
}

function move_check(e) {
    if (posiY - getY(e) < -50) {
        moveY = "bottom";
    }
}

function end_check() {
    if (moveY == "bottom" && window.innerWidth < 700) {
        $('.form').css('bottom', '-650px');
    } else if (moveY == "bottom" && window.innerWidth < 1000) {
        $('.form').css('bottom', '-450px');
    }
}

function getY(e) {
    //縦方向の座標を取得
    return (e.originalEvent.touches[0].pageY);
}

