export default function section2() {
    const nextButton = `<div class="arrow-next" onClick="$('.slider').slick('slickNext')">
        <img src="./assets/section2/slider-arrow.png">
    </p>
    `
    const prevButton = `<div class="arrow-prev" onClick="$('.slider').slick('slickPrev')">
        <img src="./assets/section2/slider-arrow.png">
    </p>
    `

    $(".slider").slick({
        dots: true,
        dotsClass: 'slide-pager',
        prevArrow: $('.arrow-prev'),
        nextArrow: $('.arrow-next'),
        centerMode: true,
        centerPadding: '0%',
        speed: 600,
        infinite: true,
        slidesToShow: 1,
        autoplay: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
    });

    $('.slide-pager').wrap('<div class="slider-controller"></div>');
    $('.slider-controller').prepend(prevButton);
    $('.slider-controller').append(nextButton);
}