import SmoothScroll from "smooth-scroll";
import section1 from './section1';
import section2 from "./section2";
import contact from './contact';

new SmoothScroll('a[href*="#"]', {
  speed: 900,
  speedAsDuration: true,
  offset: 90
});

section1();
contact();
section2();

//sp-fvの吹き出し移動に必要な情報
const fvMain = document.getElementsByClassName('fv-main__middle')

//料金プラン部分の背景画像設定に必要な情報
const Section3 = $('.section3');
const Section3Bg = $('.section3-background');
const Section3Bg2 = $('.section3-background2');
const Section3Img = $('.section3-img2__content');
const Section3Img2 = $('.section3-img2');
const Section3Title = $('.section3-price-title');

$(window).on('load resize', function () {
  //sp-fvの吹き出し移動処理
  let Misalignment = (560 - window.innerWidth) / 2
  if (window.innerWidth < 560) {
    $(fvMain).css('right', Misalignment + 'px');
  }

  //section3最上部、最下部の背景画像設定
  let Section3BgHeight = $('.section3-background').innerHeight();
  let Section3BgHeight2 = $('.section3-background2').innerHeight();
  $(Section3Bg).css('top', '-' + Section3BgHeight + 'px');
  $(Section3Bg2).css('bottom', '-' + Section3BgHeight2 + 'px');

  //section3の高さを規定
  let Section3TopY = $('.section3').offset().top;
  let Section3FlowY = $('.section3-flow').offset().top;
  let Section3FlowHeight = $('.section3-flow').outerHeight();
  let Section3Height = Section3FlowY + Section3FlowHeight - Section3TopY;
  $(Section3).css('height', Section3Height + 'px');
  $(Section3).css('margin-bottom', Section3BgHeight2 + 80 + 'px');

  //section3-imgの大きさ規定
  let ContentWidth = $('.content-wrapper').innerWidth();
  let section3ImgRight = (ContentWidth - ContentWidth * 0.8) / 2;
  let Img2 = $('.section3-img2__content').innerHeight();
  if (window.innerWidth > 1260) {
    $(Section3Img).css('width', '');
    $(Section3Img).css('right', '');
  }
  if (window.innerWidth < 1260) {
    $(Section3Img).css('width', ContentWidth * 0.8 + 'px');
    $(Section3Img).css('right', section3ImgRight + 'px');
    $(Section3Img2).css('height', Img2 + 'px');
  }
  if (window.innerWidth < 700) {
    $(Section3Img).css('right', '');
    $(Section3Img).css('width', '');
    $(Section3Img2).css('height', '');
  }

  //section3titleの場所決定
  $(Section3Title).css('top', '-' + 70 + 'px');
  if (window.innerWidth < 700) {
    $(Section3Title).css('top', '-' + 30 + 'px');
  }
});

