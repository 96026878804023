export default function section1() {
    //section1の文字アニメーション管理
    const section1First = $('.section1-first__right--text2')
    const section1FirstY = $('.section1-first__right--text2').offset().top;
    const section1FirstHeight = $('.section1-first__right--text2').outerHeight();
    const section1Second = $('.section1-second__right--text2')
    const section1SecondY = $('.section1-second__right--text2').offset().top;
    const section1Third = $('.section1-third__right--text2')
    const section1ThirdY = $('.section1-third__right--text2').offset().top;
    const section1Fourth = $('.section1-fourth__right--text2')
    const section1FourthY = $('.section1-fourth__right--text2').offset().top;
    const windowHeight = $(window).height();
    const section1FirstBottom = section1FirstY + section1FirstHeight;
    const section1SecondBottom = section1SecondY + section1FirstHeight;
    const section1ThirdBottom = section1ThirdY + section1FirstHeight;
    const section1FourthBottom = section1FourthY + section1FirstHeight;

    $(window).scroll(function () {
        let scroll = $(this).scrollTop();
        let windowBottom = scroll + windowHeight
        if (windowBottom >= section1FirstBottom) {
            $(section1First).addClass("is-active");
        }
    });
    $(window).scroll(function () {
        let scroll = $(this).scrollTop();
        let windowBottom = scroll + windowHeight
        if (windowBottom >= section1SecondBottom) {
            $(section1Second).addClass("is-active");
        }
    });
    $(window).scroll(function () {
        let scroll = $(this).scrollTop();
        let windowBottom = scroll + windowHeight
        if (windowBottom >= section1ThirdBottom) {
            $(section1Third).addClass("is-active");
        }
    });
    $(window).scroll(function () {
        let scroll = $(this).scrollTop();
        let windowBottom = scroll + windowHeight
        if (windowBottom >= section1FourthBottom) {
            $(section1Fourth).addClass("is-active");
        }
    });

    //section1台形の高さ設定
    const Section1Main = $('.section1-main1')
    const Section1Main2 = $('.section1-main2');

    $(window).on('load resize', function () {
        //section1480px以上の背景サイズ取得
        let section1Width = $('.section1-main1').innerWidth();
        let section1Height = section1Width / 1.5;
        let section1Height2 = section1Width / 1.8;

        if (window.innerWidth > 1480) {
            $(Section1Main).css('height', section1Height + 'px');
            $(Section1Main2).css('height', section1Height + 'px');
        }
        if (window.innerWidth > 1800) {
            $(Section1Main).css('height', section1Height2 + 'px');
            $(Section1Main2).css('height', section1Height2 + 'px');
        }
        if (window.innerWidth < 1480) {
            $(Section1Main).css('height', '');
            $(Section1Main2).css('height', '');
        }
    });
}